<template>
  <v-app >
    <v-container fluid fill-height >
        <v-card elevation="24" outlined tile height="100%" width="100%" style="background-color: #e4e7ec;">
            <v-row>
                <v-col cols="4">
                    <v-card elevation="0" height="100%" width="100%" class="mt-4 ml-4" style="background-color: #e4e7ec;">
                        <v-card-title class="justify-center" style="color: #039BE5;font-size: 90px;font-weight: bold;margin-top: 50px;">本日入場人數</v-card-title>
                        <div :style="'color: #263959;font-size:' + fontSize + ';text-align: center;font-weight: bold;line-height: 450px;'">{{dataList.totalPeople}}</div>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card elevation="0" height="100%" width="100%" class="mt-4 ml-4" style="background-color: #e4e7ec;">
                        <v-card-title class="justify-center" style="color: #039BE5;font-size: 90px;font-weight: bold;margin-top: 50px;">目前在場人數</v-card-title>
                        <div :style="'color: #E53935;font-size: ' + fontSize + ';text-align: center;font-weight: bold;line-height: 450px;'">{{dataList.nowPeople}}</div>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card elevation="0" height="100%" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 80px;color: rgb(38, 57, 89);margin-top: 40px;">{{dateStr}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 80px;color: rgb(38, 57, 89);margin-top: 40px;">{{timeStr}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 80px;color: rgb(38, 57, 89);margin-top: 40px;">{{city}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 60px;color: rgb(38, 57, 89);margin-top: 40px;" v-if="false">溫度<span style="color: #6f3826;margin-right: 30px;">{{temperature}}°C</span>濕度<span style="color: #6f3826;">{{humidity}}%</span></v-card-title>
                        <table width="100%" style="margin-top: 50px;" v-if="checkPlusFunc('heatindexEnable')">
                            <tr>
                                <td  colspan="4" style="text-align: center;" :class="heatColor">
                                    <span class="font-weight-black" style="font-size: 40px;">熱指數 {{heatindex}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-weight-black hotDetal light-green accent-2">
                                    第一級<br/>
                                    注意<br/>
                                    26.7<br/>
                                </td>
                                <td class="font-weight-black hotDetal yellow accent-2">
                                    第二級<br/>
                                    格外注意<br/>
                                    32.2<br/>
                                </td>
                                <td class="font-weight-black hotDetal orange accent-2">
                                    第三級<br/>
                                    危險<br/>
                                    40.6<br/>
                                </td>
                                <td class="font-weight-black hotDetal deep-orange accent-4">
                                    第四級<br/>
                                    極度危險<br/>
                                    54.4<br/>
                                </td>
                            </tr>
                        </table>
                    </v-card>
                </v-col>
            </v-row>
            <v-row style="margin-top: -30px;">
                <v-col cols="6">
                    <v-card elevation="0" outlined tile min-height="350px" width="100%"  style="position: relative">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 50px; background-color:#D9D4CF;color:#3E4348;">最新五筆<span style="font-size: 50px;background-color:#D9D4CF;color:#C65146">入場</span>紀錄</v-card-title>
                        <ul class="menu">
                            <li v-for="(item, index) in dataList.recentIn" :key="item.ioTime + index">
                                <v-card class="ml-2" elevation="0" outlined tile height="430px" width="100%" style="background-color:#fffcf0;">
                                    <img :src="'data:image/jpg;base64,' + item.photo"
                                    width="100%"
                                    >
                                    <div style="font-size: 28px;font-weight: bold; color:#1B5E20; text-align: center;">{{item.ioTime}}</div>
                                    <div style="font-size: 34px;font-weight: bold; color:#263959; text-align: center;">{{item.name}}</div>
                                    <div style="font-size: 18px;font-weight: bold; color:#EF6C00; text-align: center;" v-for="(item2, index2) in item.memberPropertyListText" :key="item.ioTime + item2 + index2">{{item2}}</div>
                                    
                                </v-card>
                            </li>
                        </ul>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card elevation="0" height="100%" width="100%" outlined tile>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 50px;background-color:#D9D4CF;color:#3E4348">最新五筆<span style="font-size: 50px;background-color:#D9D4CF;color:#1B5E20">出場</span>紀錄</v-card-title>
                        <ul class="menu" style="width: 938px;">
                            <li v-for="(item, index) in dataList.recentOut" :key="item.ioTime + index">
                                <v-card class="ml-2" elevation="0" outlined tile min-height="430px" width="100%" style="background-color:#fffcf0;">
                                    <img :src="'data:image/jpg;base64,' + item.photo" width="100%">
                                    <div style="font-size: 28px;font-weight: bold; color:#1B5E20; text-align: center;">{{item.ioTime}}</div>
                                    <div style="font-size: 34px;font-weight: bold; color:#263959; text-align: center;">{{item.name}}</div>
                                    <div style="font-size: 18px;font-weight: bold; color:#EF6C00; text-align: center;" v-for="(item2, index2) in item.memberPropertyListText" :key="item.ioTime + item2 + index2">{{item2}}</div>
                                    
                                </v-card>
                            </li>
                        </ul>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import loadingMask from '@/components/public/loadingMask.vue'
import commonMixin from '@/utils/commonMixin';
import {inOutInfo, getSysParamListByCode, getHeatindex} from "@/api/hyirecoApi"
import {getWeatherInfo} from "@/api/weatherApi"

export default {
  name: 'dashbord',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    //this.clearCountdown();
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
    //console.log("loginAuth = " + JSON.stringify(this.loginAuth));
  },
  destroyed() {
    clearInterval(this.tiemTimer);
    this.tiemTimer = null;
    clearInterval(this.dataTimer);
    this.dataTimer = null;
    clearInterval(this.inoutTime);
    this.inoutTime = null;
  },
  mounted(){
    this.getMemberProperty();
    this.getDataList();
    this.getCityData();
    this.getWeather();
    this.showDateTime();
    this.getHeatColor();
    //"codeA1","codeB2"

    //this.heatindexEnable = this.loginAuth.orgnization.heatindexEnable === "Y" ? true : false;

    this.tiemTimer = setInterval(() => {
        this.showDateTime();
        this.getWeatherRefresh();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
    },1000);

    this.dataTimer =  setInterval(() => {
        this.getDataList();
    },3000);

    this.inoutTime = setInterval(() => {
      this.resetCountdown();
      //this.getWeather();
      //console.log("vm.loginAuth.timeStart = " + vm.loginAuth.timeStart);
    },1500000);
  },
  methods: {
    getWeatherRefresh: function(){
        var date = new Date();
        var mm = date.getMonth() + 1;

        if (this.nowHour != mm) {
            this.nowHour = mm;
            this.getWeather();
        }
    },
    getWeather: function(){
        var vm = this;
        var date = new Date();
        var hour = parseInt(date.getHours()/3)*3;

        var dateStr = [
            date.getFullYear(),
            ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1),
            (date.getDate() > 9 ? '' : '0') + date.getDate()
        ].join('-');

        var requestStr = "";
        requestStr += "locationName=" + vm.city;
        requestStr += "&elementName=T,RH";
        requestStr += "&timeFrom=" + dateStr + "T" + ((hour > 9 ? '' : '0') + hour.toString()) + ":00:00"

        getWeatherInfo(requestStr).then(function(response) {
            if ("200" == response.status) { 
                //console.log("response = " + JSON.stringify(response.data.records.locations[0].location[0].weatherElement));
                var responseData = response.data.records.locations[0].location[0].weatherElement;
                
                var T = responseData.filter(function(item) {
                    return item.elementName === 'T';
                })[0].time[0].elementValue[0].value;

                var RH = responseData.filter(function(item) {
                    return item.elementName === 'RH';
                })[0].time[0].elementValue[0].value;

                vm.temperature = T;
                vm.humidity = RH;
                console.log("T:" + T + ",RH:" + RH);

                if (parseFloat(T) >= 26.7) {
                    var para = {
                        temperature: T,
                        humidity: RH
                    };
                    
                    getHeatindex(para, vm.auth).then(function(response) {
                        if ("200" == response.status) {
                            var responseData = response.data;
                            console.log("responseData = " + JSON.stringify(responseData));
                            //vm.heatindex
                            if (responseData.statusCode === "1") {
                                vm.heatindex = responseData.heatindex;
                                console.log("heatindex = " + vm.heatindex);
                            }

                            vm.getHeatColor();
                            
                        }
                    }); 
                } else {
                    vm.heatindex = "32.2";
                    vm.getHeatColor();
                }

            }
        });
    },
    getHeatColor: function(){
        var vm = this;
        var intHeat = parseFloat(vm.heatindex);
        if (intHeat < 32.2) {
            vm.heatColor = "light-green accent-2";
        } else if (intHeat < 40.6) {
            vm.heatColor = "yellow accent-2";
        } else if (intHeat < 54.4) {
            vm.heatColor = "orange accent-2";
        } else if (intHeat >= 54.4) {
            vm.heatColor = "deep-orange accent-2";
        }
    },
    getDataList: function() { 
        var vm = this;
        
        inOutInfo(vm.$route.params.jsId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    //console.log("vm.memberPropertyList = " + JSON.stringify(vm.memberPropertyList));
                    vm.dataList = responseData;
                    // vm.testCount += 10;
                    // vm.dataList.totalPeople = vm.testCount.toString();
                    // vm.dataList.nowPeople = (vm.testCount-10).toString();
                    vm.fontSize = (vm.dataList.totalPeople.length+vm.dataList.nowPeople.length) > 4 ? "360px" : "500px";
                    if (vm.memberPropertyList.length > 0) {

                        vm.dataList.recentIn = vm.dataList.recentIn.map(function(item) {
                            var tmpArr = item.memberPropertyList.map(function(item2){
                                var tmpP = vm.memberPropertyList.find(function(item3){
                                    return item2 === item3.value;
                                });
                                return tmpP.text;
                            });

                            var tmpTime = item.ioTime.split(" ");
                            item.ioTime = tmpTime.length > 1 ? tmpTime[1] : item.ioTime;

                            item.memberPropertyListText = tmpArr;
                            return item;
                        });

                        vm.dataList.recentOut = vm.dataList.recentOut.map(function(item) {
                            var tmpArr = item.memberPropertyList.map(function(item2){
                                var tmpP = vm.memberPropertyList.find(function(item3){
                                    return item2 === item3.value;
                                });
                                return tmpP.text;
                            });

                            var tmpTime = item.ioTime.split(" ");
                            item.ioTime = tmpTime.length > 1 ? tmpTime[1] : item.ioTime;
                            item.memberPropertyListText = tmpArr;
                            return item;
                        });
                    }
                    

                    //console.log("responseData = " + JSON.stringify(vm.dataList));
                }
            }
        });
    },
    getCityData: function() {
        var tmpArr = this.loginAuth.orgnization.location.split(' ');
        this.city = tmpArr.length > 0 ? tmpArr[0] : "";
        //console.log("city = " + tmpArr);
    },
    showDateTime: function() {
        var date = new Date();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();

        this.dateStr = [
            date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
        ].join('-');


        this.timeStr = [
            (date.getHours() > 9 ? '' : "0") + date.getHours(),
            (date.getMinutes() > 9 ? '' : "0") + date.getMinutes(),
            (date.getSeconds() > 9 ? '' : "0") + date.getSeconds()
        ].join(':');

    },
    getMemberProperty: function(){
        var vm = this;
        getSysParamListByCode("people",vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                if (responseData.statusCode === "1") {
                    responseData.sysParam.childParamList.forEach(function(para){
                        var tmpArr =  para.childParamList.map(function(child){
                                        var item = new Object();
                                        item.text = para.name + "-" + child.name;
                                        item.value = child.code;
                                        return item;
                                        });
                        vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
                        console.log("tmpArr = " + JSON.stringify(vm.memberPropertyList));
                    });
                }
            }
        });
    },
  },
  watch: {
  },
  data: () => ({
    userName: "",
    isLoading: false, //loading畫面的開關
    dataList: {},
    city: "",
    dateStr: "",
    timeStr: "",
    heatindex: "32.2",
    heatColor: "light-green accent-2",
    dataTimer: null,
    tiemTimer: null,
    inoutTime: null,
    memberPropertyList: [],
    temperature: "",
    humidity: "",
    //heatindexEnable: false,
    fontSize: "500px",
    nowHour: 0,
    testCount: 0,
  }),
  components: {
    loadingMask,
  },
}
</script>

<style scoped>
    .hotDetal{
        text-align: center;
        font-size: 30px;
    }

    .column{
        float:left;
        list-style:none; /* 將預設的列表符號去掉 */
        padding:0; /* 將預設的內邊距去掉 */
        margin:0; /* 將預設的外邊距去掉 */
        width: 20%;
        height: 300px;
        min-height: 300px;
    }

    .menu{
        margin: 0;
        padding: 0;
        display:inline-block;
        margin-left: -10px;
        margin-right: 5px;

    }

    .menu li { 
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        width: 20%;
    }
</style>